
<template>
  <v-card-login :caminho="logo">
    <v-card-content>
      <validation-observer ref="loginForm">
        <b-form
          class="auth-login-form mt-2"
          style="padding: 1rem !important"
          @submit.prevent="Password"
        >
          <p style="font-size: 12px">Digite sua nova senha:</p>
          <!-- email -->
          <b-form-group label-for="email" label="E-mail">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="userEmail"
                name="login-email"
                :disabled="true"
                :state="errors.length > 0 ? false : null"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--senha -->
          <b-form-group label="Senha" label-for="password">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Senha"
              vid="password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="password"
                  @input="password_check"
                  v-model="password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="login-password"
                  ref="password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <v-dica-senha :password="password"
            :has_lenght="has_lenght"
            :has_uppercase="has_uppercase"
            :has_lowercase="has_lowercase"
            :has_number="has_number"
            />
          <b-form-group label="Confirmar Senha" label-for="confirmedPassword">
            <validation-provider
              #default="{ errors }"
              rules="required|confirmed:password"
              name="Confirmar Senha"
            >
              <b-input-group class="input-group-merge">
                <b-form-input id="confirmedPassword" v-model="passwordConfirm" :type="passwordFieldType"/>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--  button -->
          <v-button
            color="primary"
            @onSubmit="Password"
            :blocked="lengthScreen.width < breakPoint"
            label="Redefinir"
            icone="Send"
            :disabled="!validateForm"
          />
        </b-form>
      </validation-observer>
    </v-card-content>
  </v-card-login>
</template>

<script>
// Libs de Validação
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Libs do Bootstrap
import {
  BAlert,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";

// chamada de component do projeto
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
const VDicaSenha = () => import('@/views/components/VDicaSenha/VDicaSenha.vue')

// request
import { resetPassword } from "@/webservice/request/auth";

import { caminhoLogo, breakPointScreen } from '@/plugins/contantes'


export default {
  components: {
    // BSV
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    ToastificationContent,
    VDicaSenha
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: this.$route.query.user,
      password: "",
      passwordConfirm: "",
      lengthScreen: {
        width: 0,
        height: 0,
      },
      erro: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_lenght: false
    };
  },
  computed: {
    validateForm() {
      return (
        this.password !== "" &&
        this.passwordConfirm !== "" &&
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_lenght
      );
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    logo () {
      return caminhoLogo()
    },
    breakPoint () {
      return breakPointScreen("md")
    }
  },
  methods: {
    password_check() {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_lenght = /\S{7}/.test(this.password);
    },
    getLengthScreen() {
      this.lengthScreen.width = window.innerWidth;
      this.lengthScreen.height = window.innerHeight;
    },

    Password() {
      if (this.$route.query.user !== undefined) {
        const payload = {
          email: this.$route.query.user,
          code: this.$route.query.code.replace(/ /g, "+"),
          password: this.password,
          confirmPassword: this.passwordConfirm,
        };
        this.$loading.start()
        resetPassword(payload)
          .then((response) => {
            this.$loading.done()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Senha alterada com sucesso!",
                  title: "SUCESSO",
                  variant: "success",
                  toaster: "b-toaster-bottom-right",
                },
              },
              {
                position: "bottom-right",
                timeout: 8000,
              }
            );
            this.$router.push("/login").catch(() => {});
          })
          .catch((error) => {
            this.$loading.done()
            this.$notification(error, this.ToastificationContent);
          });
      } else {
        const payload = {
          email: this.email,
          password: this.password,
          confirmPassword: this.passwordConfirm,
          code: this.code,
        };
        this.$loading.start()
        resetPassword(payload)
          .then((response) => {
            this.$loading.done()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Senha alterada com sucesso!",
                  title: "SUCESSO",
                  variant: "success",
                  toaster: "b-toaster-bottom-right",
                },
              },
              {
                position: "bottom-right",
                timeout: 8000,
              }
            );
            this.$router.push("/login").catch(() => {});
          })
          .catch((error) => {
            this.$loading.done()
            this.$notification(error, this.ToastificationContent);
          });
      }
    },
  },

  created() {
    window.addEventListener("resize", this.getLengthScreen);
    this.getLengthScreen();
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

</style>